<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit Job</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" :md="openInSightPanel ? '9' : '12'">
        <b-card>
          <b-tabs fill>
            <b-tab title="Information" active>
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col>
                    <b-form-group label="Title" label-for="v-title">
                      <b-form-input disabled v-model="formData.title" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Subject" label-for="v-subject">
                      <b-form-input disabled v-model="formData.subject" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Code" label-for="v-code">
                      <b-form-input disabled v-model="formData.code" />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Type" label-for="v-job_type">
                      <v-select
                        disabled
                        v-model="formData.job_type"
                        :options="jobTypes"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Name" label-for="v-name">
                      <b-form-input disabled v-model="formData.name" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Standard Time" label-for="v-mins">
                      <b-input-group append="Minute(s)">
                        <b-form-input
                          disabled
                          class="text-right"
                          v-model.number="formData.hrs"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <validation-provider
                      #default="{ errors }"
                      name="Minute(s)"
                      rules="between:0,59"
                    >
                      <b-form-group label="Standard Time" label-for="v-mins">
                        <b-input-group append="Minute(s)">
                          <b-form-input
                            disabled
                            class="text-right"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                            :state="errors.length > 0 ? false : null"
                            v-model.number="formData.mins"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <b-form-group label="Price" label-for="v-standard_price">
                      <b-form-input
                        disabled
                        class="text-right"
                        v-model.number="formData.standard_price"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <h2>Checklists</h2>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Technician Checklists"
                      label-for="v-technician"
                    >
                      <v-select
                        disabled
                        id="v-technician"
                        v-model.number="formData.technician_checklist_id"
                        :options="checklists"
                        :reduce="(checklist) => +checklist.value"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Customer Checklists"
                      label-for="v-customer"
                    >
                      <v-select
                        disabled
                        id="v-customer"
                        v-model.number="formData.customer_checklist_id"
                        :options="checklists"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <h2>Required Skill Set</h2>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group label="Staff Needed">
                      <b-form-select
                        disabled
                        v-model="formData.staff_quantity"
                        :options="staffNeeded"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col>
                    <b-form-group label="Competency">
                      <b-form-select
                        disabled
                        v-model="formData.staff_quantity"
                        :options="staffNeeded"
                      />
                    </b-form-group>
                  </b-col>
                </b-row> -->

                <b-row>
                  <b-col>
                    <h2>Propose Spares Needed</h2>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <JobSpares
                        :isDisabled="true"
                        :data-source="dataSourceJobSpares"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <h2>Additional Instructions</h2>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <JobInstructions
                        :isDisabled="true"
                        :data-source="dataSourceJobInstructions"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <h2>Notes</h2>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <quill-editor
                        disabled
                        ref="quillEditorRef"
                        v-model="formData.notes"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right">
                    <b-button
                      type="button"
                      class="mr-1"
                      variant="outline-danger"
                      @click="goBack()"
                    >
                      Back
                    </b-button>
                    <b-button type="button" variant="success" @click="onEdit()">
                      Edit
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
            <span v-if="$route.query.id">
              <!-- <b-tab title="Logs"></b-tab>
              <b-tab title="Comments"></b-tab> -->
            </span>
          </b-tabs>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :md="openInSightPanel ? '3' : '12'"
        v-if="openInSightPanel"
      >
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { required, between } from '@validations';
import jobsApi from '@api/jobs';
import checklistsApi from '@api/checklist_template_headers';
import JobSpares from './components/JobSpares';
import JobInstructions from './components/JobInstructions';
import { queryParameters } from '@/schema';

export default {
  name: 'JobsManage',
  components: {
    JobSpares,
    JobInstructions,
  },
  data: () => ({
    required,
    between,
    openInSightPanel: false,
    onFileUpload: null,
    content: null,
    dataSourceJobSpares: [],
    dataSourceJobInstructions: [],
    checklists: [],
    staffNeeded: [
      { value: 1, text: 'One' },
      { value: 2, text: 'Two' },
    ],
    jobTypes: [
      'Configure',
      'Dismantle',
      'Inspect',
      'Install',
      'Renew',
      'Repair',
      'Replace',
      'Service',
      'Survey Job Type',
    ],
    formData: {
      id: 0,
      service_id: 0,
      technician_checklist_id: null,
      customer_checklist_id: null,
      title: null,
      subject: null,
      notes: null,
      code: null,
      name: null,
      file: null,
      job_type: null,
      hrs: 0,
      mins: 0,
      standard_time: 0,
      standard_price: 0,
      status: 'O',

      base64: null,
    },
  }),
  watch: {
    onFileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {};
      }
    },
  },
  computed: {
    getImageSource: {
      get() {
        if (+this.$route.query.id) {
        }
      },
    },
  },
  mounted() {
    this.loadChecklists();

    if (+this.$route.query.id) {
      this.loadJob();
    }
  },
  methods: {
    loadJob() {
      jobsApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data.job_competencies.data) {
            //
          }

          if (data.job_instructions.data) {
            this.dataSourceJobInstructions = data.job_instructions.data;
          }

          if (data.job_spares.data) {
            this.dataSourceJobSpares = data.job_spares.data;
          }

          if (data.standard_time) {
            data.hrs = Math.floor(+data.standard_time / 60);
            data.mins = +data.standard_time % 60;
          }

          this.formData = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadChecklists() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      checklistsApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (const item of data) {
            items.push({ label: item.title, value: +item.id, item });
          }

          this.checklists = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    goBack() {
      this.$router.push('/pages/masterlists/jobs');
    },
    onEdit() {
      this.$router.push(
        `/pages/masterlists/jobs/manage?id=${this.$route.query.id}`
      );
    },
    onSubmit() {
      let app;

      const formData = this.formData;
      const hoursConverted = Number(formData.hrs) * 60;
      const minutesConverted = Number(formData.mins);
      formData.standard_time = hoursConverted + minutesConverted;
      formData.notes = this.content;

      if (this.formData.id) {
        app = jobsApi.update(this.formData);
      } else {
        app = jobsApi.add(this.formData);
      }

      app
        .then(({ data }) => {
          this.$router.push(`/pages/masterlists/jobs/view?id=${data.id}`);
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onFileRemove() {
      this.onFileUpload = null;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
